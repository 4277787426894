
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'redirect',
})
export default class extends Vue {
  public created() {
    const { params, query } = this.$route;
    const { path } = params;
    this.$router.replace({ path: '/' + path, query });
  }

  public render(h: any) {
    /* 刷新空模板 */
    return h();
  }
}
